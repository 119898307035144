import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../components/Header/Header';
import './Library.css';
import library from '../../assets/images/library.png'; // Make sure to import your feeding icon
import { apiInstance } from '../../utils/axiosInstance';// Adjust the import path as necessary
import { fetchAuthSession } from 'aws-amplify/auth';

import {useNavigate} from 'react-router-dom';

function Library() {
  const navigate = useNavigate();
  const [readPosts, setReadPosts] = useState([]);
  const [clickedOutBooks, setClickedOutBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const userId = useSelector((state) => state.user.userId);

  const MINIMUM_LOADING_TIME = 400; 

 
  useEffect(() => {
    const fetchData = async () => {
      const startTime = Date.now();
      setIsLoading(true);
      setShowLoading(true);

      try {
        // Get the current session to retrieve the JWT token
        const session = await fetchAuthSession();

        const jwtToken = session.tokens.idToken.toString();

        console.log(userId, 'userId');

        const [piecesResponse, booksResponse] = await Promise.all([
          apiInstance.get('/clicks/latestuserpieces', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          }),
          apiInstance.get('/clicks/latestuserclicks', {
            params: { user_id: userId, limit: 10 },
            headers: { Authorization: `Bearer ${jwtToken}` }
          })
        ]);

        console.log('booksResponse.data', booksResponse.data)

        setReadPosts(piecesResponse.data || []);
        setClickedOutBooks(booksResponse.data || []);

        

        const endTime = Date.now();
        const loadTime = endTime - startTime;
        
        if (loadTime < MINIMUM_LOADING_TIME) {
          setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => setShowLoading(false), 500); // Fade out effect
          }, MINIMUM_LOADING_TIME - loadTime);
        } else {
          setIsLoading(false);
          setTimeout(() => setShowLoading(false), 500); // Fade out effect
        }
      } catch (error) {
        console.error('Error fetching library data:', error);
        setIsLoading(false);
        setTimeout(() => setShowLoading(false), 500); // Fade out effect
      }
    };

    fetchData();
    console.log('read posts', readPosts)
  }, [userId]);


  const goToPreviousPiece = (hash) => {
    window.scrollTo(0, 0);

  
    navigate(`/piece/${hash}`, { 
      state: { 
        prevRoute: `/library`
      } 
    });
  };

  const generateHash = (length) => {
    return crypto.getRandomValues(new Uint8Array(length))
      .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
      .slice(0, length);
  };
  
  const generateCustomTimestamp = () => {
    return Date.now().toString(36);
  };


  const onLibraryButtonPress = (book, position) => {
    const timestamp = generateCustomTimestamp();
    const uniqueHash = generateHash(12);
    const clickType = 'L'; // 'L' for Library click
    const paddedPosition = position.toString().padStart(2, '0');
    const pieceHash = '00000000'; // 8 zeros for piece hash in library clicks
    
    const sid = `${uniqueHash}-${clickType}-${paddedPosition}-${pieceHash}-${book.profile_hash}-${timestamp}`;
    
    const encodedSid = encodeURIComponent(sid);

    const url = new URL(book.affiliate_url);
    url.searchParams.append('sid', sid);
    
    window.open(url.toString(), '_blank');

    const affiliateClickData = {
      unique_hash: uniqueHash,
      affiliate_id: book.affiliate_id,
      affiliate_url: book.affiliate_url,
      book_author_name: book.book_author_name,
      book_image_url: book.book_image_url,
      book_title: book.book_title,
      product_link_url: book.product_link_url,
      consuming_user: userId,
      retailer_id: book.retailer_id,
      click_type: 'RECENT-LIBRARY',
      position: position,
      publishing_user: book.publishing_user,
      profile_hash: book.profile_hash,
      piece_writer_name: book.piece_writer_name
    };

    console.log('Library Click Data:', affiliateClickData);

    apiInstance.post('/clicks/affiliate/create', affiliateClickData)
      .then(response => console.log('API Call Successful:', response.data))
      .catch(error => console.error('Error in API Call:', error));
  };

  const renderReadPosts = () => {
    if (readPosts.length === 0) {
      return <p className="library__empty-message">No pieces read - go get-a-reading </p>;
    }

    return (
      <div className="library__post-feed">
        {readPosts.map((post, index) => (
          <div key={index} className="library__post-item" onClick={() => goToPreviousPiece(post.piece_hash)}>
            <img
              src={post.piece_image_jpeg || post.piece_image_webp || post.piece_image}
              alt={post.title}
              className="library__post-image"
            />
            <div className="library__post-details">
              <div className="library__post-title-container">
                <span className="library__post-title">
                  {post.piece_title}
                  {post.serial_part_number && (
                    <span className="library__post-part-number"> - Part {post.serial_part_number}</span>
                  )}
                </span>
              </div>
              <div className="library__post-author">{post.piece_writer_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };


  const renderClickedBooks = () => {
    if (clickedOutBooks.length === 0) {
      return <p className="library__empty-message">No books clicked on - go get exploring</p>;
    }

    return (
      <div className="library__book-feed">
        {clickedOutBooks.map((book, index) => (
          <div 
            key={index} 
            className="library__book-item"
            onClick={() => onLibraryButtonPress(book, index + 1)}
          >
            <img
              src={book.book_image_url}
              alt={book.book_title}
              className="library__book-image"
            />
            <div className="library__book-details">
              <div className="library__book-title">{book.book_title || book.book_raw_title}</div>
              <div className="library__book-author">{book.book_author_name}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="library">
      <Header />
      {/* {showLoading ? (
        <div className={`loading-screen ${!isLoading ? 'fade-out' : ''}`}>
          <img src={library} alt="Loading" className="loading-icon" />
        </div>
      ) : ( */}
        <>
          <h2 className="library__title">Your Library</h2>
          <div className="library__section">
            <h3 className="library__section-title">Pieces you've read</h3>
            {renderReadPosts()}
          </div>

          <div className="library__section">
            <h3 className="library__section-title">Books you've clicked on</h3>
            {renderClickedBooks()}
          </div>
        </>
      {/* )} */}
    </div>
  );
}

export default Library;